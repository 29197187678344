import styled from 'styled-components';
import { PageHeader, mobileMixin } from '../styles';
import Img from 'gatsby-image';
import { COLOR } from '../constants';

export const InsightsPageHeader = styled(PageHeader)`
  width: 100%;
  max-width: 750px;
  color: ${COLOR.DARKBLUE};
  ${mobileMixin(`
    font-size: 58px;
  `)};
`;

export const InsightPageExcerpt = styled.h2`
  font-size: 24px;
  color: ${COLOR.LIGHTBLUE};
`;

export const InsightsFeaturedImage = styled(Img)`
  border-radius: 15px;
`;

export const InsightsContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  h1 {
    font-size: 32px;
    color: ${COLOR.DARKBLUE};
    margin: 30px 0px;
  }

  h2 {
    font-size: 24px;
    color: ${COLOR.LIGHTBLUE};
    margin: 15px 0px;
  }

  h3 {
    font-size: 17px;
    color: ${COLOR.DARKBLUE};
    margin: 15px 0px;
  }

  p {
    font-size: 16px;
    margin: 15px 0px;
  }

  ol,
  ul {
    width: calc(100% - 50px);
    max-width: 750px;
    margin-left: 25px;
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
    border-radius: 10px;

    img {
      height: auto;
      width: 100%;
      margin: 0 auto;
      align-self: center;
    }
  }
`;
