import React from 'react';
import { graphql } from 'gatsby';
import { Layout, Container } from '../components';
import useIsDesktop from '../lib/isDesktop';
import { InsightsContent, InsightsPageHeader } from './styles';
import { FlexCenter, PaddingMedium } from '../styles';
import { COLOR } from '../constants';

const Policy = ({ data }: any) => {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const isDesktop = useIsDesktop();

  return (
    <Layout isDesktop={isDesktop} title={frontmatter.title}>
      <Container flexDirection={'column'} maxWidth="900px">
        <FlexCenter>
          <InsightsPageHeader>{frontmatter.title}</InsightsPageHeader>
        </FlexCenter>
        <InsightsContent dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
      <PaddingMedium backgroundcolor={COLOR.DARKBLUE} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`;

export default Policy;
